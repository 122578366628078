.cm-s-monokai.CodeMirror {
  color: #f8f8f2;
  background: #272822;
}

.cm-s-monokai div.CodeMirror-selected {
  background: #49483e;
}

.cm-s-monokai .CodeMirror-line::-moz-selection, .cm-s-monokai .CodeMirror-line > span::-moz-selection, .cm-s-monokai .CodeMirror-line > span > span::-moz-selection {
  background: #49483efc;
}

.cm-s-monokai .CodeMirror-line::selection, .cm-s-monokai .CodeMirror-line > span::selection, .cm-s-monokai .CodeMirror-line > span > span::selection {
  background: #49483efc;
}

.cm-s-monokai .CodeMirror-gutters {
  background: #272822;
  border-right: 0;
}

.cm-s-monokai .CodeMirror-guttermarker {
  color: #fff;
}

.cm-s-monokai .CodeMirror-guttermarker-subtle, .cm-s-monokai .CodeMirror-linenumber {
  color: #d0d0d0;
}

.cm-s-monokai .CodeMirror-cursor {
  border-left: 1px solid #f8f8f0;
}

.cm-s-monokai span.cm-comment {
  color: #75715e;
}

.cm-s-monokai span.cm-atom, .cm-s-monokai span.cm-number {
  color: #ae81ff;
}

.cm-s-monokai span.cm-comment.cm-attribute {
  color: #97b757;
}

.cm-s-monokai span.cm-comment.cm-def {
  color: #bc9262;
}

.cm-s-monokai span.cm-comment.cm-tag {
  color: #bc6283;
}

.cm-s-monokai span.cm-comment.cm-type {
  color: #5998a6;
}

.cm-s-monokai span.cm-property, .cm-s-monokai span.cm-attribute {
  color: #a6e22e;
}

.cm-s-monokai span.cm-keyword {
  color: #f92672;
}

.cm-s-monokai span.cm-builtin {
  color: #66d9ef;
}

.cm-s-monokai span.cm-string {
  color: #e6db74;
}

.cm-s-monokai span.cm-variable {
  color: #f8f8f2;
}

.cm-s-monokai span.cm-variable-2 {
  color: #9effff;
}

.cm-s-monokai span.cm-variable-3, .cm-s-monokai span.cm-type {
  color: #66d9ef;
}

.cm-s-monokai span.cm-def {
  color: #fd971f;
}

.cm-s-monokai span.cm-bracket {
  color: #f8f8f2;
}

.cm-s-monokai span.cm-tag {
  color: #f92672;
}

.cm-s-monokai span.cm-header, .cm-s-monokai span.cm-link {
  color: #ae81ff;
}

.cm-s-monokai span.cm-error {
  color: #f8f8f0;
  background: #f92672;
}

.cm-s-monokai .CodeMirror-activeline-background {
  background: #373831;
}

.cm-s-monokai .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #fff !important;
}

/*# sourceMappingURL=index.5920f6fd.css.map */
